'use client';

import { faArrowRightLong, faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@pickleballinc/react-ui';
import { usePathname, useRouter } from 'next/navigation';
import React, { useCallback, useState } from 'react';

import { postDevAuth as postDevAuthAction } from '@/app/dev/auth/postDevAuth';

export const LoginForm = () => {
	const [loading, setIsloading] = useState(false);
	const [password, setPassword] = useState('');
	const [error, setError] = useState<string | null>(null);
	const pathname = usePathname();
	const router = useRouter();

	const postDevAuth = useCallback(async () => {
		setIsloading(true);
		setError(null);
		const response = await postDevAuthAction(password);
		if (response.statusCode === 200) {
			if (pathname === '/dev/auth') {
				router.push('/');
			} else {
				router.refresh();
			}
		} else {
			setIsloading(false);
			setError(response.message);
		}
	}, [router, password]);

	return (
		<div className="flex h-screen w-screen items-center justify-center">
			<div className="relative flex w-full max-w-72 flex-col">
				<input
					type="password"
					className={`block h-14 w-full rounded border border-gray-300 px-4 py-2 text-center outline-none ${error ? 'border-error-500/60 ring-4 ring-error-500/20 focus:outline-none' : 'focus:border-experiment/60 focus:outline-none focus:ring-4 focus:ring-experiment/20'}`}
					placeholder="Enter password"
					value={password}
					disabled={loading}
					onChange={(e) => {
						if (error) setError(null);
						setPassword(e.currentTarget.value);
					}}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							postDevAuth();
						}
					}}
				/>
				{error && <div className="mt-1 text-center text-sm text-error-500">{error}</div>}
				<Button
					variant="primary"
					className="mt-6 block w-full justify-center rounded !bg-experiment text-center text-sm"
					onClick={postDevAuth}
					disabled={loading}
				>
					{loading ? (
						<>
							Please wait... <FontAwesomeIcon icon={faSpinnerThird} size="sm" className="ml-2" />
						</>
					) : (
						<>
							Submit <FontAwesomeIcon icon={faArrowRightLong} size="sm" className="ml-2" />
						</>
					)}
				</Button>
			</div>
		</div>
	);
};
